var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-checkbox', {
    class: "custom-control-warning CustomCheckbox  ".concat(_vm.isSearchClassBooking ? 'label_disabled' : ''),
    attrs: {
      "disabled": _vm.isSearchClassBooking,
      "switch": ""
    },
    on: {
      "input": function input(val) {
        return _vm.handleUpdate(val);
      }
    },
    model: {
      value: _vm.isDirectFlightOnly,
      callback: function callback($$v) {
        _vm.isDirectFlightOnly = $$v;
      },
      expression: "isDirectFlightOnly"
    }
  }, [_c('span', {
    staticClass: "text-body font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t('flight.directFlightSelect')))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }