<template>
  <b-form-checkbox
    v-model="isDirectFlightOnly"
    :disabled="isSearchClassBooking"
    switch
    :class="`custom-control-warning CustomCheckbox  ${
      isSearchClassBooking ? 'label_disabled' : ''
    }`"
    @input="(val) => handleUpdate(val)"
  >
    <span class="text-body font-weight-bolder">{{
      $t('flight.directFlightSelect')
    }}</span>
  </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    numberOfStop: {
      type: Number,
      default: () => 0,
    },
    isSearchClassBooking: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDirectFlightOnly = ref(false)

    watch(
      () => props.numberOfStop,
      val => {
        if (val === 0) {
          isDirectFlightOnly.value = true
        } else {
          isDirectFlightOnly.value = false
        }
      },
      { immediate: true },
    )

    const handleUpdate = val => {
      if (val === false) {
        emit('update:numberOfStop', 99)
      } else {
        emit('update:numberOfStop', 0)
      }
    }

    return {
      isDirectFlightOnly,
      handleUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.CustomCheckbox::v-deep {
  display: flex;
  align-items: center;
  .custom-control-label {
    margin: 4px 0 !important;

    span {
      font-size: 1.2rem;
    }
  }

  &.label_disabled {
    .custom-control-label {
      span {
        color: #999999 !important;
      }
    }
  }
}
</style>
